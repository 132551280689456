import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  FormProvider,
  Controller,
  useWatch,
  useFieldArray,
  SubmitHandler,
} from "react-hook-form";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
// import { useTheme } from "@mui/material/styles";
// import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import CardHeader from "../../common/CardHeader";
import { useLazyGetUserListConfigQuery } from "../../../service/slice/usersSlice";
import CustomReactSelectJs from "../../common/textfields/CustomReactSelectJs";
import { LoaderWithText } from "../../common/Loaders";
import { thisFieldRequired } from "../../../utils/constants";
// import { useLazyGetPaymentsListConfigQuery } from "../../../service/slice/system-configuration/paymentsTypesSlice";
// import {
//   useLazyGetDiscountListConfigQuery,
//   useLazyGetPromoListConfigQuery,
// } from "../../../service/slice/system-configuration/discountPromoSlice";
import { useLazyGetBuyerListConfigQuery } from "../../../service/slice/client-management/buyer/buyerListSlice";
// import useFileView from "../../../hooks/useFileView";
import {
  useAddScheduleMutation,
  // useAddSchedulePaymentMutation,
  // useGetSchedulePaymentQuery,
  useGetScheduleQuery,
  // useLazyGetServiceReceiptQuery,
} from "../../../service/slice/rental-and-services/rentalServicesSlice";
// import FileViewModal from "../../common/FileViewModal";
// import PaymentUpload from "../../client-management/buyers/active-buyer-form/payment/PaymentUpload";
// import IconButtonMdi from "../../common/IconButtonMdi";
// import { getValueFromPercent } from "../../../utils/helpers/mathHelper";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../utils/notificationToast";
import AddBuyerModal from "./AddBuyerModal";

interface Props {
  form: any;
  serviceType: string;
}

const ScheduleAndPayment = ({ serviceType, form }: Props) => {
  // const theme = useTheme();
  // const [openUpload, setOpenUpload] = useState<boolean>(false);
  // const [printArLoading, setPrintArLoading] = useState<boolean>(false);
  // const [printOrLoading, setPrintOrLoading] = useState<boolean>(false);
  // const [viewDoc, handelToggleViewDoc] = useFileView();

  const isPast = moment().isAfter(moment(form.watch("date")), "day");

  const [
    getUserListTrigger,
    { data: userListData, isLoading: userListIsLoading, error: userListError },
  ] = useLazyGetUserListConfigQuery();

  const [
    getBuyerListTrigger,
    {
      data: buyerListData,
      isLoading: buyerListIsLoading,
      error: buyerListError,
    },
  ] = useLazyGetBuyerListConfigQuery();

  // const [
  //   getPaymentListTrigger,
  //   {
  //     data: paymentListData,
  //     isLoading: paymentListIsLoading,
  //     error: paymentListError,
  //   },
  // ] = useLazyGetPaymentsListConfigQuery();

  // const [
  //   getDiscountListTrigger,
  //   {
  //     data: discountListData,
  //     isLoading: discountListIsLoading,
  //     error: discountListError,
  //   },
  // ] = useLazyGetDiscountListConfigQuery();

  // const [
  //   getPromoListTrigger,
  //   {
  //     data: promoListData,
  //     isLoading: promoListIsLoading,
  //     error: promoListError,
  //   },
  // ] = useLazyGetPromoListConfigQuery();

  const [
    addMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      reset: addReset,
      isSuccess: addIsSuccess,
      data: addScheduleData,
    },
  ] = useAddScheduleMutation();

  // const [
  //   getReceiptTrigger,
  //   {
  //     data: receiptData,
  //     isLoading: receiptIsLoading,
  //     isSuccess: receiptIsSuccess,
  //     error: receiptError,
  //   },
  // ] = useLazyGetServiceReceiptQuery();

  const {
    data: paymentData,
    isLoading: paymentDataIsLoading,
    error: paymentDataError,
    isFetching: paymentDataIsFetching,
  } = useGetScheduleQuery(form.watch("id") || addScheduleData?.data?.id);

  const loading =
    userListIsLoading ||
    buyerListIsLoading ||
    // paymentListIsLoading ||
    // discountListIsLoading ||
    // promoListIsLoading ||
    paymentDataIsLoading ||
    paymentDataIsFetching;

  const saveLoading = addIsLoading;

  const buyerOptions = useMemo(() => {
    return (
      buyerListData?.data?.map((item) => {
        return {
          label:
            item.referenceNumber + " - " + item.firstName + " " + item.lastName,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [buyerListData?.data]);

  const userOptions = useMemo(() => {
    return (
      userListData?.data?.map((item) => {
        return {
          label: item.firstName + " " + item.lastName,
          value: item.id!.toString(),
        };
      }) ?? []
    );
  }, [userListData?.data]);

  // const discountOptions = useMemo(() => {
  //   return (
  //     discountListData?.data?.map((item) => ({
  //       label: item.name,
  //       value: `${item.id}|${item.type}|${item.value}`,
  //     })) ?? []
  //   );
  // }, [discountListData?.data]);

  // const promoOptions = useMemo(() => {
  //   return (
  //     promoListData?.data?.map((item) => ({
  //       label: item.name,
  //       value: `${item.id}|${item.type}|${item.value}`,
  //     })) ?? []
  //   );
  // }, [promoListData?.data]);

  const { control, setValue, handleSubmit, watch } = form;

  const onSubmit: SubmitHandler<any> = (dataFields) => {
    const {
      unit,
      owner,
      deceasedId,
      // deceased,
      date,
      time,
      assistedBy,
      coordinatedBy,
      service,
      funeralService,
      placeOfWake,
      casket,
      casketHeight,
      casketWidth,
      casketLength,
    } = dataFields;

    addMutate({
      queryParams: serviceType.toUpperCase(),
      bodyParams: {
        serviceId: service.id,
        buyerId: Number(owner?.value) ?? null,
        unitId: unit.value ? Number(unit.value) : null,
        deceasedId: Number(deceasedId?.value) ?? null,
        // deceased: deceasedId?.value
        //   ? undefined
        //   : {
        //       firstName: deceased?.firstName,
        //       lastName: deceased?.lastName,
        //       middleName: deceased?.middleName,
        //       birthDate: deceased?.birthDate,
        //       deathDate: deceased?.deathDate,
        //     },
        date: moment(date).format("YYYY-MM-DD"),
        time: moment(time).format("HH:mm"),
        funeralService: funeralService,
        wakePlace: placeOfWake,
        casket: casket,
        casketSize:
          casket === "OVERSIZE"
            ? {
                height: casketHeight,
                width: casketWidth,
                length: casketLength,
              }
            : undefined,
        assistedBy: Number(assistedBy.value),
        coordinatedBy: Number(coordinatedBy.value),
      },
    });
  };

  const onError = () => {
    // checkErrors();
  };

  const showDiscountPromo = (val: string): string => {
    const initVal = val.split("|");

    if (initVal[1] === "AMOUNT") {
      return `less ${initVal[2]}`;
    } else if (initVal[1] === "PERCENTAGE") {
      return `less ${initVal[2]}%`;
    }

    return "";
  };

  if (userListError) {
    errorDisplayOrNavigate({
      error: userListError,
      toastId: "user-list-config",
    });
  }

  if (buyerListError) {
    errorDisplayOrNavigate({
      error: buyerListError,
      toastId: "buyer-list-config",
    });
  }

  // if (paymentListError) {
  //   errorDisplayOrNavigate({
  //     error: paymentListError,
  //     toastId: "payment-list-config",
  //   });
  // }

  // if (discountListError) {
  //   errorDisplayOrNavigate({
  //     error: discountListError,
  //     toastId: "discount-list-config",
  //   });
  // }

  // if (promoListError) {
  //   errorDisplayOrNavigate({
  //     error: promoListError,
  //     toastId: "promo-list-config",
  //   });
  // }

  if (paymentDataError) {
    errorDisplayOrNavigate({
      error: paymentDataError,
      toastId: "payment-data",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-payment",
    });

    addReset();
  }

  // if (receiptError) {
  //   errorDisplayOrNavigate({
  //     error: receiptError,
  //     toastId: "receipt",
  //   });
  // }

  if (addIsSuccess) {
    showToastSuccess({
      text: `Schedule successfully saved.`,
      toastId: "add-payment",
    });
    addReset();
    form.setValue("id", addScheduleData?.data?.id);
    form.setValue("paymentId", addScheduleData?.data?.paymentId);
    form.setValue(
      "servicePaymentTermId",
      addScheduleData?.data?.id
    );
  }

  const buyerInfoWatch = useWatch({
    control,
    name: "owner",
  });

  useEffect(() => {
    if (
      (form.watch("service")?.id && form.watch("id")) ||
      (form.watch("service")?.id && form.watch("date"))
    ) {
      getUserListTrigger();
      getBuyerListTrigger();
      // getDiscountListTrigger();
      // getPromoListTrigger();
    }
    // getPaymentListTrigger();
  }, [form.watch("id"), form.watch("service"), form.watch("date")]);

  // useEffect(() => {
  //   if (receiptIsSuccess) {
  //     handelToggleViewDoc(true, receiptData?.data.link ?? "");
  //   }
  // }, [receiptIsSuccess, receiptData?.data?.link]);

  useEffect(() => {
    if (paymentData?.data?.id) {
      const {
        id,
        paymentId,
        term,
        totalPayment,
        promoDiscount,
        assisted,
        coordinated,
        deceased,
        service,
        buyer,
        unit,
        encoded,
        date,
        time,
      } = paymentData.data;

      setValue("id", id);
      setValue("servicePaymentTermId", id);
      setValue("paymentId", paymentId);
      setValue("date", date);
      setValue(
        "time",
        new Date(moment(time, "HH:mm").format("YYYY-MM-DD hh:mm A"))
      );
      setValue("owner", {
        label:
          buyer.referenceNumber +
          " - " +
          buyer.firstName +
          " " +
          buyer.lastName,
        value: buyer.id,
      });
      if (unit?.lotStatus) {
        setValue("unit", {
          label: unit?.lotCategory?.name + "-" + unit?.lotType?.name,
          value: unit.id,
        });
      } else if (unit?.vaultStatus) {
        setValue("unit", {
          label: unit?.vaultColumn?.name + "-" + unit?.vaultLevel?.name,
          value: unit.id,
        });
      } else if (unit?.cryptStatus) {
        setValue("unit", {
          label: unit?.cryptColumn?.name + "-" + unit?.cryptLevel?.name,
          value: unit.id,
        });
      }
      setValue("encodedBy", encoded);
      setValue("assistedBy", {
        label: assisted.firstName + " " + assisted.lastName,
        value: assisted.id,
      });
      setValue("coordinatedBy", {
        label: coordinated.firstName + " " + coordinated.lastName,
        value: coordinated.id,
      });
      setValue("service", service);
      setValue("price", service.regular);
      setValue("serviceDetails.price", service.regular);
      setValue("serviceIncludedAssets", service.serviceIncludedAssets);
      setValue("serviceRequiredDocuments", service.serviceRequiredDocuments);
      setValue("deceasedId", {
        label: deceased.firstName + " " + deceased.lastName,
        value: deceased.id,
      });
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentData?.data?.buyer?.id !== form.watch("owner").value) {
      form.resetField("deceasedId");
      form.resetField("deceased");
      form.resetField("unit");
    }
  }, [buyerInfoWatch]);

  const isDisabled = addScheduleData?.data?.id || paymentData?.data?.id;

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting other information.." />
    </Box>
  ) : (
    <FormProvider {...form}>
      {(form.watch("service")?.id && form.watch("id")) ||
      (form.watch("service")?.id && form.watch("date")) ? (
        <Box marginBottom={2} paddingY={1}>
          <CardHeader title={`${_.capitalize(serviceType)} Schedule`} />
          <Box paddingX={3} paddingTop={3} paddingBottom={2}>
            <Typography variant="textfieldLabel">
              Date: {moment(form.watch("date")).format("MMMM DD, YYYY")}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Owner/Reference Number
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={
                        // serviceType.toUpperCase() === "CREMATION"
                        //   ?
                        [
                          {
                            label: "Add New Buyer",
                            value: "Add",
                          },
                          ...buyerOptions,
                        ]
                        // : buyerOptions
                      }
                      name="owner"
                      placeholder="Owner/Reference Number"
                      isDisabled={isPast || isDisabled}
                      isRequired
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">Unit</Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={buyerListData?.data
                        .find(
                          (buyer) => buyer.id === Number(buyerInfoWatch.value)
                        )
                        ?.units.map((unit) => {
                          if (unit.lotStatus) {
                            return {
                              label:
                                unit?.lotCategory?.name +
                                "-" +
                                unit?.lotType?.name,
                              value: unit.buyerUnitId!.toString(),
                            };
                          } else if (unit.vaultStatus) {
                            return {
                              label:
                                unit?.vaultColumn?.name +
                                "-" +
                                unit?.vaultLevel?.name,
                              value: unit.buyerUnitId!.toString(),
                            };
                          } else if (unit.cryptStatus) {
                            return {
                              label:
                                unit?.cryptColumn?.name +
                                "-" +
                                unit?.cryptLevel?.name,
                              value: unit.buyerUnitId!.toString(),
                            };
                          } else {
                            return "";
                          }
                        })}
                      name="unit"
                      placeholder="Unit"
                      isDisabled={isPast || isDisabled}
                      noOptionsMessage={
                        serviceType.toUpperCase() === "CREMATION"
                          ? "N/A (Not applicable)"
                          : "No options"
                      }
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Funeral Service
                    </Typography>
                    <Controller
                      name="funeralService"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Funeral Service"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                          disabled={isPast || isDisabled}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Place of Wake
                    </Typography>
                    <Controller
                      name="placeOfWake"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          placeholder="Place of Wake"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                          disabled={isPast || isDisabled}
                        />
                      )}
                    />
                  </Box>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography variant="textfieldLabel">Casket</Typography>
                      <Controller
                        name="casket"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <RadioGroup {...field} value={watch("casket")} row>
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="STANDARD"
                                label="Standard"
                                control={<Radio />}
                                disabled={isPast || isDisabled}
                              />
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="OVERSIZE"
                                label="Oversize"
                                control={<Radio />}
                                disabled={isPast || isDisabled}
                              />
                            </RadioGroup>
                            {error && (
                              <FormHelperText error>
                                {error.message}
                              </FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </Box>
                    {watch("casket") === "OVERSIZE" && (
                      <div>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Height
                          </Typography>
                          <Controller
                            name="casketHeight"
                            control={control}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                placeholder="Height"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                                disabled={isPast || isDisabled}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Length
                          </Typography>
                          <Controller
                            name="casketLength"
                            control={control}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                placeholder="Length"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                                disabled={isPast || isDisabled}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography variant="textfieldLabel">
                            Width
                          </Typography>
                          <Controller
                            name="casketWidth"
                            control={control}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                placeholder="Width"
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                                sx={{
                                  width: "100%",
                                }}
                                disabled={isPast || isDisabled}
                              />
                            )}
                          />
                        </Box>
                      </div>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="textfieldLabel">Time</Typography>
                    <Controller
                      name="time"
                      control={control}
                      disabled={isPast || isDisabled}
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            {...field}
                            disabled={isPast || isDisabled}
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                error: fieldState.error ? true : false,
                                helperText: fieldState.error?.message,
                                fullWidth: true,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Box>
                  <div>
                    <Typography variant="textfieldLabel">
                      Name of Deceased
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={buyerListData?.data
                        .find(
                          (buyer) => buyer.id === Number(buyerInfoWatch.value)
                        )
                        ?.deceaseds.map((deceased) => {
                          return {
                            label: deceased.firstName + " " + deceased.lastName,
                            value: deceased.id!.toString(),
                          };
                        })}
                      name="deceasedId"
                      placeholder="Name of Deceased"
                      isDisabled={isPast || isDisabled}
                      isRequired
                    />
                  </div>
                  {/* <div>
                  <Typography variant="textfieldLabel">First Name</Typography>
                  <Controller
                    name={`deceased.firstName`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="First Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        value={deceasedInfoWatch?.firstName ?? ""}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">Last Name</Typography>
                  <Controller
                    name={`deceased.lastName`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Last Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        value={deceasedInfoWatch?.lastName ?? ""}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">Middle Name</Typography>
                  <Controller
                    name={`deceased.middleName`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        placeholder="Middle Name"
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                        value={deceasedInfoWatch?.middleName ?? ""}
                        sx={{
                          width: "100%",
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">
                    Date of Birth
                  </Typography>
                  <Controller
                    name={`deceased.birthDate`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          {...field}
                          value={deceasedInfoWatch?.birthDate ?? null}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              error: fieldState.error?.message ? true : false,
                              helperText: fieldState.error?.message,
                              fullWidth: true,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">
                    Date of Death
                  </Typography>
                  <Controller
                    name={`deceased.deathDate`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          {...field}
                          value={deceasedInfoWatch?.deathDate ?? null}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              error: fieldState.error?.message ? true : false,
                              helperText: fieldState.error?.message,
                              fullWidth: true,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </div> */}
                  <Box>
                    <Typography variant="textfieldLabel">
                      Assisted By
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={userOptions}
                      name="assistedBy"
                      placeholder="Assisted By"
                      isDisabled={isPast || isDisabled}
                      isRequired
                    />
                  </Box>
                  <Box>
                    <Typography variant="textfieldLabel">
                      Coordinated By
                    </Typography>
                    <CustomReactSelectJs
                      control={control}
                      options={userOptions}
                      name="coordinatedBy"
                      placeholder="Assisted By"
                      isDisabled={isPast || isDisabled}
                      isRequired
                    />
                  </Box>
                  {paymentData?.data?.encoded && (
                    <div>
                      <Typography variant="textfieldLabel">
                        Encoded By:{" "}
                        {form.watch("encodedBy").firstName +
                          " " +
                          form.watch("encodedBy").lastName}
                      </Typography>
                    </div>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box
            // bgcolor={
            //   paymentData?.data?.id ? theme.palette.grey[100] : "transparent"
            // }
            padding={"10px"}
            borderRadius={"5px"}
          >
            <Stack direction="row" spacing={2} justifyContent="center" mt={6}>
              {!form.watch("paymentId") && !isPast && !isDisabled && (
                <Button
                  variant="button-primary"
                  // disabled={scheduleId ? false : true}
                  sx={{
                    marginTop: 4,
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  Schedule
                </Button>
              )}
            </Stack>
            <AddBuyerModal
              open={form.watch("owner").value === "Add"}
              title={`Add New Buyer`}
              buyerPaymentId={
                (paymentData?.data?.paymentId || addScheduleData?.data?.id) ??
                null
              }
              onClose={() => {
                form.resetField("owner");
                getBuyerListTrigger();
              }}
            />
          </Box>
        </Box>
      ) : (
        <Alert severity="warning">
          Please select a service to fill up this form.
        </Alert>
      )}
    </FormProvider>
  );
};
export default ScheduleAndPayment;
