import { useState } from "react";
import { useDebounce } from "use-debounce";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CustomPagination from "../../common/CustomPagination";
import RowsPerPageComp from "../../common/RowsPerPageComp";
import TableSearchField from "../../common/textfields/TableSearchField";
import { SchedulePaymentModel } from "../../../model/accountManagementPaymentModel";
import moment from "moment";
import ConfirmModal from "../../common/ConfirmModal";
import useDeleteTableItem from "../../../hooks/useDeleteTableItem";
import { useVoidPaymentMutation } from "../../../service/slice/account-management/payment/AccountManagementPaymentSlice";
import { errorDisplayOrNavigate, showToastSuccess } from "../../../utils/notificationToast";

const headerLabels: string[] = [
  "Posted Date",
  "OR Number",
  "Amount",
  "Description",
  "Penalty",
];

type Props = {
  handleToggleUpload: () => void;
  schedules: SchedulePaymentModel[];
};

const PaymentSchedule = ({ handleToggleUpload, schedules }: Props) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>("");
  const [entries, setEntries] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [voidItem, handleToggleVoid] =
    useDeleteTableItem<SchedulePaymentModel>();

  const [debounceSearch] = useDebounce(searchValue, 500);

  const [
    voidMutation,
    {
      isLoading: voidIsLoading,
      isSuccess: voidIsSuccess,
      reset: voidReset,
      error: voidError,
    },
  ] = useVoidPaymentMutation();

  const loading = false;
  const listData = schedules;
  const count = schedules.length;

  const confirmVoid = () => voidMutation(voidItem.data?.id as number);

  if (voidIsSuccess) {
    showToastSuccess({
      text: `${voidItem.data?.orNumber} successfully void!`,
      toastId: "or-get",
    });

    handleToggleVoid(false, null);
    voidReset();
  }

  if (voidError) {
    errorDisplayOrNavigate({
      error: voidError,
      toastId: "or-get",
    });

    voidReset();
  }

  return (
    <>
      <Grid container marginBottom={2} alignItems="center">
        <Grid item xs={12} lg={8}>
          <TableSearchField
            searchValue={debounceSearch ?? ""}
            pathname={``}
            querySearch={(value: string) => ""}
            handleSearchSetState={(e) => setSearchValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RowsPerPageComp
            entries={entries}
            pathname=""
            querySearch={(entry) => ""}
            changeEntry={(e) => setEntries(Number(e.target.value))}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "primary.dark",
              }}
            >
              {headerLabels.map((item, index) => (
                <TableCell
                  key={index}
                  sx={{
                    color: "common.white",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headerLabels.length} align="center">
                  <CircularProgress
                    color="primary"
                    style={{ margin: "20px 0" }}
                  />
                </TableCell>
              </TableRow>
            ) : listData && listData.length > 0 ? (
              listData
                .slice((page - 1) * entries, (page - 1) * entries + entries)
                .map((item, index) => (
                  <TableRow
                    key={index}
                    onClick={
                      item.orNumber.includes("VOIDED")
                        ? undefined
                        : () => handleToggleVoid(true, item)
                    }
                    sx={{
                      cursor: item.orNumber.includes("VOIDED") ? "unset" : "pointer",
                      "&:nth-of-type(even)": {
                        backgroundColor: "grey.100",

                        td: {
                          backgroundColor: "grey.100",
                        },
                      },
                      "&:nth-of-type(odd) td": {
                        backgroundColor: "common.white",
                      },
                    }}
                  >
                    <TableCell>
                      {item.datePosted
                        ? moment(item.datePosted).format("MMMM DD, YYYY")
                        : item.datePosted}
                    </TableCell>
                    <TableCell>{item.orNumber}</TableCell>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.penalty}</TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  NO DATA FOUND
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        pathname=""
        querySearch={(page) => ""}
        totalCount={count ?? 0}
        currentPage={page}
        pageSize={entries}
        nextPageSetState={() => setPage(page + 1)}
        prevPageSetState={() => setPage(page - 1)}
        selectPageSetState={(page) => setPage(page)}
      />
      <Stack direction="row" spacing={2} alignItems="center" paddingTop={3}>
        <Typography fontWeight={600} color={"primary"}>
          Payment Documents
        </Typography>
        <Button
          variant="button-secondary"
          sx={{
            width: 150,
          }}
          onClick={handleToggleUpload}
        >
          Upload
        </Button>
      </Stack>
      <ConfirmModal
        isLoading={voidIsLoading}
        open={voidItem.open}
        text={`Are you sure to void${voidItem.data?.orNumber ? " " + voidItem.data?.orNumber : ""}?`}
        closeFn={() => handleToggleVoid(false, null)}
        confirmFn={confirmVoid}
      />
    </>
  );
};

export default PaymentSchedule;
