import { ListFetchQueryType } from "../../../../model/global";
import { ErrorResponseModel, apiSlice, errorHandler } from "../../../apiSlice";
import {
  AccountManagementServicePaymentDtoModel,
  AccountManagementUnitPaymentDetailsDtoModel,
  AccountManagementUnitPaymentDtoModel,
  AddPaymentAccountManagDocBody,
  PaymentBodyModel,
} from "../../../../model/accountManagementPaymentModel";
import {
  AddBuyerPaymentDtoModel,
  BuyerPaymentDtoModel,
  BuyerPaymentReceiptDtoModel,
  PaymentDocDtoModel,
} from "../../../../model/buyerModel";

export const endpoints = {
  "payment-list": (
    type: "units" | "services",
    page: number,
    entries: number,
    search: string
  ) =>
    `/account-management/payment/list/${type}?perPage=${entries}&currentPage=${page}&search=${search}`,
  "payment-details": (id: number, type: "unit" | "service") =>
    `/account-management/payment/get/${id}/${type}`,
  "add-payment": (type: "UNIT" | "SERVICE") =>
    `/account-management/payment/add/${type}`,
  "upload-payment-doc": "/buyer/upload/payment",
  "add-payment-doc": "/buyer/payment/file/add",
  "get-payment-doc-list": (id: number, type: "UNIT" | "SERVICE") =>
    `/buyer/payment/file/get/${id}/${type}`,
  "delete-payment-doc": (id: number) => `/buyer/payment/file/delete/${id}`,
  "get-receipt": (
    id: number,
    receiptType: string,
    paymentType: "UNIT" | "SERVICE"
  ) =>
    `/buyer/receipt/print/${id}/${receiptType}/${paymentType}?paymentFor=MONTHLY`,
  "void-payment": (id: number) => `/account-management/payment/void/${id}`,
};

interface PaymentListFetchQueryType extends ListFetchQueryType {
  type: "units" | "services";
}

export const paymentSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccManagUnitPaymentList: builder.query<
      AccountManagementUnitPaymentDtoModel,
      PaymentListFetchQueryType
    >({
      query: (param) => ({
        url: endpoints["payment-list"](
          param.type,
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        { type: "account-management-payment", id: args.type },
      ],
    }),
    getAccManagServicePaymentList: builder.query<
      AccountManagementServicePaymentDtoModel,
      PaymentListFetchQueryType
    >({
      query: (param) => ({
        url: endpoints["payment-list"](
          param.type,
          param.page,
          param.entries,
          param.search
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        { type: "account-management-payment", id: args.type },
      ],
    }),
    getPaymentDetailsUnit: builder.query<
      AccountManagementUnitPaymentDetailsDtoModel,
      { id: number; type: "unit" | "service" }
    >({
      query: (param) => ({
        url: endpoints["payment-details"](param.id, param.type),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, args) => [
        {
          type: "account-management-payment-details",
          id: `${args.id}-${args.type}`,
        },
      ],
    }),
    addPaymentAccountManag: builder.mutation<
      AddBuyerPaymentDtoModel,
      { id: number; type: "UNIT" | "SERVICE"; bodyData: PaymentBodyModel }
    >({
      query: (body) => ({
        url: endpoints["add-payment"](body.type),
        body: body.bodyData,
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, args) => [
        {
          type: "account-management-payment-details",
          id: `${args.id}-${args.type}`,
        },
      ],
    }),
    addPaymentAccountManagDoc: builder.mutation<
      void,
      AddPaymentAccountManagDocBody
    >({
      query: (body) => ({
        url: endpoints["add-payment-doc"],
        body: body.body,
        method: "POST",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "account-management-payment-docs",
          id: `${arg.type} - ${arg.body.buyerPaymentId}`,
        },
      ],
    }),
    getPaymentAccountManagDocs: builder.query<
      PaymentDocDtoModel,
      { id: number; type: "unit" | "service" }
    >({
      query: (param) => ({
        url: endpoints["get-payment-doc-list"](
          param.id,
          param.type === "unit" ? "UNIT" : "SERVICE"
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "account-management-payment-docs",
          id: `${arg.type} - ${arg.id}`,
        },
      ],
    }),
    deletePaymentAccountManagDoc: builder.mutation<
      void,
      {
        id: number;
        buyerPaymentId: number;
        type: "unit" | "service";
      }
    >({
      query: (param) => ({
        url: endpoints["delete-payment-doc"](param.id),
        method: "DELETE",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      invalidatesTags: (result, error, arg) => [
        {
          type: "account-management-payment-docs",
          id: `${arg.type} - ${arg.buyerPaymentId}`,
        },
      ],
    }),
    getAccountManagReceipt: builder.query<
      BuyerPaymentReceiptDtoModel,
      { id: number; receiptType: string; paymentType: "UNIT" | "SERVICE" }
    >({
      query: (param) => ({
        url: endpoints["get-receipt"](
          param.id,
          param.receiptType,
          param.paymentType
        ),
        method: "GET",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
      providesTags: (result, error, arg) => [
        {
          type: "account-management-payment-receipt",
          id: `${arg.paymentType} - ${arg.id}`,
        },
      ],
    }),
    voidPayment: builder.mutation<void, number>({
      query: (param) => ({
        url: endpoints["void-payment"](param),
        method: "PUT",
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const {
  useLazyGetAccountManagReceiptQuery,
  useGetAccManagUnitPaymentListQuery,
  useGetAccManagServicePaymentListQuery,
  useGetPaymentDetailsUnitQuery,
  useAddPaymentAccountManagMutation,
  useAddPaymentAccountManagDocMutation,
  useGetPaymentAccountManagDocsQuery,
  useDeletePaymentAccountManagDocMutation,
  useVoidPaymentMutation,
} = paymentSlice;
