import React, { useEffect, useState, useRef } from "react";
import _, { set } from "lodash";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { mdiPlusCircleOutline, mdiMinusCircleOutline } from "@mdi/js";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Checkbox from "@mui/material/Checkbox";
import {
  useForm,
  Controller,
  FormProvider,
  useWatch,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";

import {
  thisFieldRequired,
  receiptOpt,
  scheduledMonthsOpt,
} from "../../../../../utils/constants";
import CustomReactSelectJs from "../../../../common/textfields/CustomReactSelectJs";
import IconButtonMdi from "../../../../common/IconButtonMdi";
import {
  PaymentFieldsBody,
  UnitTypeDtoModel,
} from "../../../../../model/buyerModel";
import { getValueFromPercent } from "../../../../../utils/helpers/mathHelper";
import {
  useAddBuyerPaymentMutation,
  useGetPaymentQuery,
  useLazyGetReceiptQuery,
} from "../../../../../service/slice/client-management/buyer/buyerPaymentSlice";
import {
  errorDisplayOrNavigate,
  showToastSuccess,
} from "../../../../../utils/notificationToast";
import { PaymentTypesModel } from "../../../../../model/paymentsTypesModel";
import { LoaderWithText } from "../../../../common/Loaders";
import useFileView from "../../../../../hooks/useFileView";
import FileViewModal from "../../../../common/FileViewModal";
import { showName } from "../unit-type/SelectedUnitsList";
import PaymentUpload from "./PaymentUpload";
import {
  removeSpaces,
  replaceAllHyphenWithSpace,
  replaceAllSpaceWithHyphen,
} from "../../../../../utils/helpers/stringManipulate";

export interface PaymentForm {
  contractDate: Date | null;
  paymentFields: {
    [name: string]: string | Date | null | undefined;
  }[];
  atNeed: boolean;
  paymentTerms: "FULL-PAYMENT" | "SCHEDULED" | "RESERVATION" | string;
  fullPaymentAmount: string;
  scheduledMonths: {
    label: string | null;
    value: number | string | null;
  } | null;
  scheduledDownpayment: string;
  reservationDownpayment: string;
  paymentType: {
    label: string | null;
    value: number | null;
  };
  receipt: {
    label: string | null;
    value: string | null;
  };
  unitDetails: {
    price: string;
    pcf: string;
    payment: string;
    reservation: number | string;
    discounts: {
      value: {
        value: string | null;
        label: string | null;
      };
      id?: number;
    }[];
    promo: {
      value: {
        value: string | null;
        label: string | null;
      };
      id?: number;
    }[];
    balance: string;
  };
  orNumber: string | null;
}

type OptionsProps = {
  label: string;
  value: string | null;
};

type Props = {
  unit: UnitTypeDtoModel;
  paymentTypeOptions: OptionsProps[];
  discountOptions: OptionsProps[];
  promoOptions: OptionsProps[];
  rawPaymentTypeData?: PaymentTypesModel[];
};

const PaymentForm = ({
  unit,
  paymentTypeOptions,
  discountOptions,
  promoOptions,
  rawPaymentTypeData,
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const buyerId = id ? Number(id) : null;

  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [viewDoc, handelToggleViewDoc] = useFileView();
  const paymentFieldRef = useRef<string[]>([]);
  const paymentFieldIdRef = useRef<number[]>([]);

  const form = useForm<PaymentForm>({
    defaultValues: {
      contractDate: null,
      paymentFields: [],
      atNeed: false,
      paymentTerms: "",
      fullPaymentAmount: "",
      scheduledMonths: null,
      scheduledDownpayment: "",
      reservationDownpayment: "",
      paymentType: {
        label: null,
        value: null,
      },
      receipt: {
        label: null,
        value: null,
      },
      unitDetails: {
        price: "",
        pcf: "",
        payment: "",
        reservation: "",
        discounts: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        promo: [
          {
            value: {
              label: null,
              value: null,
            },
          },
        ],
        balance: "",
      },
      orNumber: null,
    },
  });

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    getValues,
    setError,
    clearErrors,
  } = form;

  const [
    addMutate,
    {
      error: addError,
      isLoading: addIsLoading,
      reset: addReset,
      isSuccess: addIsSuccess,
      data: addData,
    },
  ] = useAddBuyerPaymentMutation();

  const [
    getReceiptTrigger,
    { data: receiptData, isSuccess: receiptIsSuccess, error: receiptError },
  ] = useLazyGetReceiptQuery();

  const {
    data: paymentData,
    isLoading: paymentDataIsLoading,
    error: paymentDataError,
    isFetching: paymentDataIsFetching,
  } = useGetPaymentQuery(unit.id);

  const loading = paymentDataIsLoading || paymentDataIsFetching;
  const saveLoading = addIsLoading;

  const fullPaymentWatch = useWatch({
    control,
    name: "fullPaymentAmount",
  });

  const scheduledDownpaymentWatch = useWatch({
    control,
    name: "scheduledDownpayment",
  });

  const reservationDownpaymentWatch = useWatch({
    control,
    name: "reservationDownpayment",
  });

  const priceWatch = useWatch({
    control,
    name: "unitDetails.price",
  });

  const discountWatch = useWatch({
    control,
    name: "unitDetails.discounts",
  });

  const receiptWatch = useWatch({
    control,
    name: "receipt",
  });

  const promoWatch = useWatch({
    control,
    name: "unitDetails.promo",
  });

  const {
    fields: discountFields,
    append: discountAppend,
    remove: discountRemove,
    replace: discountReplace,
  } = useFieldArray({
    control,
    name: "unitDetails.discounts",
  });

  const {
    fields: promoFields,
    append: promoAppend,
    remove: promoRemove,
    replace: promoReplace,
  } = useFieldArray({
    control,
    name: "unitDetails.promo",
  });

  const { fields: paymentFieldsWatch, replace: paymentFieldsReplace } =
    useFieldArray({
      control,
      name: "paymentFields",
    });

  const paymentTypeWatch = useWatch({
    name: "paymentType",
    control,
  });

  const paymentTermsWatch = useWatch({
    name: "paymentTerms",
    control,
  });

  const pcfWatch = useWatch({
    name: "unitDetails.pcf",
    control,
  });

  const atNeedWatch = useWatch({
    name: "atNeed",
    control,
  });

  const toggleUploadDoc = () => setOpenUpload((prev) => !prev);

  const onSubmit: SubmitHandler<PaymentForm> = (dataFields) => {
    const errorCount = checkErrors();

    if (errorCount.length > 0) {
      return;
    }

    const {
      contractDate,
      paymentTerms,
      scheduledMonths,
      paymentType,
      unitDetails,
      fullPaymentAmount,
      reservationDownpayment,
      scheduledDownpayment,
      paymentFields,
      orNumber,
    } = dataFields;
    const { discounts, promo, price } = unitDetails;

    let finalPromo = promo[0].value.value
      ? promo.map((item) => {
          const splitValue = item.value.value!.split("|");
          return {
            type: "PROMO",
            pdId: Number(splitValue[0]),
            amount: getValueFromPercent(splitValue[2], price),
          };
        })
      : [];

    let finalDiscount = discounts[0].value.value
      ? discounts.map((item) => {
          const splitValue = item.value.value!.split("|");

          return {
            type: "DISCOUNT",
            pdId: Number(splitValue[0]),
            amount: getValueFromPercent(splitValue[2], price),
          };
        })
      : [];

    let finalPaymentAmount = 0;

    paymentFields.forEach((item, key) => {
      const itemKeyName = paymentFieldRef.current[key];

      if (
        itemKeyName.toLowerCase().includes("cash") ||
        itemKeyName.toLowerCase().includes("amount")
      ) {
        finalPaymentAmount = parseInt(item[itemKeyName] as string);
      }
    });

    const finalPaymentFields: PaymentFieldsBody[] = paymentFields.map(
      (item, key) => {
        const itemId = paymentFieldIdRef.current[key];
        const itemKeyName = paymentFieldRef.current[key];
        return {
          fieldId: itemId,
          value: itemKeyName.toLowerCase().includes("date")
            ? (moment(item[itemKeyName]).format("YYYY-MM-DD") as string)
            : (item[itemKeyName] as string),
        };
      }
    );

    addMutate({
      buyerUnitTypeId: unit.id,
      contractDate: contractDate
        ? moment(contractDate).format("YYYY-MM-DD")
        : contractDate,
      amount: parseInt(unit.unit.amount),
      type: paymentTerms,
      pcf: parseInt(dataFields.unitDetails.pcf),
      downpayment:
        paymentTerms === "SCHEDULED" ? parseInt(scheduledDownpayment) : 0,
      reservation:
        paymentTerms === "RESERVATION" ? parseInt(reservationDownpayment) : 0,
      terms: paymentTerms === "SCHEDULED" ? Number(scheduledMonths?.value ?? 0) : 0,
      payment: {
        paymentId: Number(paymentType.value),
        amount: finalPaymentAmount,
        paymentFields: finalPaymentFields,
        orNumber: orNumber,
      },
      promoDiscount: [...finalDiscount, ...finalPromo],
    });
  };

  const onError = () => {
    checkErrors();
  };

  const checkErrors = (): number[] => {
    const dataFields = getValues();
    let errors: number[] = [];

    if (dataFields.paymentType.value === null && !atNeedWatch) {
      setError("paymentType", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    if (dataFields.receipt.value === null) {
      setError("receipt", {
        type: "required",
        message: thisFieldRequired,
      });
      errors.push(1);
    }

    return errors;
  };

  const showDiscountPromo = (val: string): string => {
    const initVal = val.split("|");

    if (initVal[1] === "AMOUNT") {
      return `less ${initVal[2]}`;
    } else if (initVal[1] === "PERCENTAGE") {
      return `less ${initVal[2]}%`;
    }

    return "";
  };

  const handleAddRemoveFieldArray = (index: number | null, key: string) => {
    if (typeof index === "number") {
      if (key === "discount") {
        discountRemove(index);
      } else {
        promoRemove(index);
      }
    } else {
      if (key === "discount") {
        discountAppend({
          value: {
            label: null,
            value: null,
          },
        });
      } else {
        promoAppend({
          value: {
            label: null,
            value: null,
          },
        });
      }
    }
  };

  const handlePaymentTermsOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;

    setValue("atNeed", false);
    setValue(
      "fullPaymentAmount",
      value === "FULL-PAYMENT" ? unit.unit.amount + unit.unit.pcfAmount : ""
    );
    setValue("scheduledDownpayment", "");
    setValue("scheduledMonths", null);
    setValue("unitDetails.payment", "");
    setValue("unitDetails.reservation", "");
    setValue("reservationDownpayment", "");
    clearErrors([
      "fullPaymentAmount",
      "reservationDownpayment",
      "scheduledMonths",
      "scheduledDownpayment",
    ]);
    // discountReplace([
    //   {
    //     value: {
    //       value: null,
    //       label: null,
    //     },
    //   },
    // ]);
    // promoReplace([
    //   {
    //     value: {
    //       value: null,
    //       label: null,
    //     },
    //   },
    // ]);
    setValue("paymentTerms", value);
  };

  if (paymentDataError) {
    errorDisplayOrNavigate({
      error: paymentDataError,
      toastId: "payment-data",
    });
  }

  if (addError) {
    errorDisplayOrNavigate({
      error: addError,
      toastId: "add-payment",
    });

    addReset();
  }

  if (receiptError) {
    errorDisplayOrNavigate({
      error: receiptError,
      toastId: "receipt",
    });
  }

  if (addIsSuccess) {
    showToastSuccess({
      text: `${`${unit.type} ${showName(
        unit.unit,
        unit.type
      )}`} successfully paid`,
      toastId: "add-payment",
    });
    getReceiptTrigger({
      id: addData?.data.id as number,
      receiptType:
        receiptWatch.label === "Official Receipt"
          ? "OR"
          : receiptWatch.label === "Acknowledgement Receipt"
          ? "AR"
          : "PR",
    });
    addReset();
  }

  useEffect(() => {
    if (atNeedWatch) {
      // const pcfConverted = getValueFromPercent(
      //   unit.configurationLVC.pcf,
      //   unit.unit?.atNeed
      // );
      setValue("unitDetails.price", unit.unit.atNeed);
      setValue("paymentTerms", "FULL-PAYMENT");
      setValue("fullPaymentAmount", unit.unit.atNeed + unit.unit.pcfAtNeed);
      setValue("scheduledDownpayment", "");
      setValue("unitDetails.pcf", String(unit.unit?.pcfAtNeed));
      setValue("scheduledMonths", null);
      setValue("unitDetails.payment", "");
      setValue("unitDetails.reservation", "");
      setValue("reservationDownpayment", "");
      clearErrors([
        "fullPaymentAmount",
        "reservationDownpayment",
        "scheduledMonths",
        "scheduledDownpayment",
      ]);
    } else {
      // const pcfConverted = getValueFromPercent(
      //   unit.configurationLVC.pcf,
      //   unit.unit?.amount
      // );
      setValue("unitDetails.pcf", String(unit.unit?.pcfAmount));
      setValue("unitDetails.price", unit.unit?.amount);
      setValue("paymentTerms", "");
      setValue("fullPaymentAmount", "");
      setValue("scheduledDownpayment", "");
      setValue("scheduledMonths", null);
      setValue("unitDetails.payment", "");
      setValue("unitDetails.reservation", "");
      setValue("reservationDownpayment", "");
      clearErrors([
        "fullPaymentAmount",
        "reservationDownpayment",
        "scheduledMonths",
        "scheduledDownpayment",
      ]);
    }
  }, [atNeedWatch]);

  useEffect(() => {
    if (receiptIsSuccess) {
      handelToggleViewDoc(true, receiptData?.data.link ?? "");
    }
  }, [receiptIsSuccess]);

  useEffect(() => {
    if (paymentData?.data?.id) {
      const {
        type,
        amount,
        totalPayment,
        promoDiscount,
        downpayment,
        reservation,
        terms,
        paymentType,
        paymentFields,
        contractDate,
        orNumber
      } = paymentData.data;
      setValue("contractDate", contractDate ? new Date(contractDate) : null);
      setValue("orNumber", orNumber);
      setValue("paymentTerms", type);
      setValue("unitDetails.payment", totalPayment.toString());
      if (type === "FULL-PAYMENT") {
        setValue("fullPaymentAmount", totalPayment.toString());
      } else if (type === "SCHEDULED") {
        setValue("scheduledDownpayment", downpayment.toString());
        setValue("scheduledMonths",terms !== 0 ? {
          label:
            scheduledMonthsOpt.find((item) => item.value === terms)?.label ??
            "",
          value: terms,
        } : null);
      } else if (type === "RESERVATION") {
        setValue("reservationDownpayment", reservation.toString());
      }

      setValue(
        "atNeed",
        `${unit.unit.atNeed + unit.unit.pcfAtNeed}.00` ===
          totalPayment.toString()
      );

      setValue("paymentType", {
        label: paymentType.name,
        value: paymentType.id,
      });

      const selectedPayType = rawPaymentTypeData?.find(
        (item) => item.id === paymentType.id
      );

      if (selectedPayType) {
        paymentFieldsReplace(
          selectedPayType.paymentFields.map((item) => {
            const paymentValue = paymentFields.find(
              (itemField) => itemField.field.id === item.id
            );

            const realValue = paymentValue?.field.entries
              .toLowerCase()
              .includes("date")
              ? new Date(paymentValue.value)
              : paymentValue?.value;

            return {
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: realValue,
            };
          })
        );
      }

      if (promoDiscount.length > 0) {
        const filteredDiscounts = promoDiscount.filter(
          (item) => item.type === "DISCOUNT"
        );
        const filteredPromos = promoDiscount.filter(
          (item) => item.type === "PROMO"
        );

        setValue(
          "unitDetails.discounts",
          filteredDiscounts.length > 0
            ? filteredDiscounts.map((item) => {
                const findRef = discountOptions.find(
                  (ref) => ref.label === item.name
                );

                return findRef
                  ? {
                      value: findRef,
                    }
                  : {
                      value: {
                        label: null,
                        value: null,
                      },
                    };
              })
            : [
                {
                  value: {
                    label: null,
                    value: null,
                  },
                },
              ]
        );

        setValue(
          "unitDetails.promo",
          filteredPromos.length > 0
            ? filteredPromos.map((item) => {
                const findRef = promoOptions.find(
                  (ref) => ref.label === item.name
                );

                return findRef
                  ? {
                      value: findRef,
                    }
                  : {
                      value: {
                        label: null,
                        value: null,
                      },
                    };
              })
            : [
                {
                  value: {
                    label: null,
                    value: null,
                  },
                },
              ]
        );
      }
    }
  }, [paymentData]);

  useEffect(() => {
    if (unit) {
      // const pcfConverted = getValueFromPercent(
      //   unit.configurationLVC.pcf,
      //   unit.unit?.amount
      // );

      setValue(
        "unitDetails.pcf",
        unit?.configurationLVC?.pcf ? String(unit.unit.pcfAmount) : ""
      );
      setValue("unitDetails.price", unit.unit?.amount ?? "");
      setValue(
        "unitDetails.balance",
        unit.unit?.amount
          ? String(parseInt(unit.unit.amount) - unit.unit.pcfAmount).includes(
              "-"
            )
            ? "0"
            : String(parseInt(unit.unit.amount) - unit.unit.pcfAmount)
          : ""
      );
    }
  }, [unit]);

  useEffect(() => {
    const discountHasValue = discountWatch.find(
      (item) => item.value.value !== null
    );

    const promoHasValue = promoWatch.find((item) => item.value.value !== null);

    let totalDiscountPromo: number[] = [];
    let paymentAmount: number = 0;

    if (discountHasValue) {
      discountWatch.forEach((item) => {
        if (item.value.value) {
          const splitValue = item.value.value.split("|");
          const discount =
            splitValue[1] === "PERCENTAGE"
              ? getValueFromPercent(splitValue[2], priceWatch)
              : parseInt(splitValue[2]);
          totalDiscountPromo.push(discount);
        }
      }, []);
    }

    if (promoHasValue) {
      promoWatch.forEach((item) => {
        if (item.value.value) {
          const splitValue = item.value.value.split("|");
          const promo =
            splitValue[1] === "PERCENTAGE"
              ? getValueFromPercent(splitValue[2], priceWatch)
              : parseInt(splitValue[2]);
          totalDiscountPromo.push(promo);
        }
      });
    }

    const finalDiscountPromo =
      totalDiscountPromo.length > 0
        ? totalDiscountPromo.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          )
        : 0;

    if (paymentTermsWatch === "FULL-PAYMENT" || !_.isEmpty(fullPaymentWatch)) {
      paymentAmount = parseInt(fullPaymentWatch);
      setValue("unitDetails.payment", fullPaymentWatch);
      setValue("unitDetails.reservation", "");
    } else if (
      paymentTermsWatch === "SCHEDULED" ||
      !_.isEmpty(scheduledDownpaymentWatch)
    ) {
      paymentAmount = parseInt(scheduledDownpaymentWatch);
      setValue("unitDetails.payment", scheduledDownpaymentWatch);
      setValue("unitDetails.reservation", "");
    } else if (
      paymentTermsWatch === "RESERVATION" ||
      !_.isEmpty(reservationDownpaymentWatch)
    ) {
      paymentAmount = parseInt(reservationDownpaymentWatch);
      setValue("unitDetails.reservation", reservationDownpaymentWatch);
      setValue("unitDetails.payment", "");
    }

    const pcfAmount = _.isEmpty(pcfWatch) ? 0 : parseInt(pcfWatch);
    const paymentAmountFinal = _.isNaN(paymentAmount) ? 0 : paymentAmount;
    setValue(
      "unitDetails.balance",
      String(
        parseInt(priceWatch) +
          pcfAmount -
          (finalDiscountPromo + paymentAmountFinal)
      ).includes("-")
        ? "0"
        : String(
            parseInt(priceWatch) +
              pcfAmount -
              (finalDiscountPromo + paymentAmountFinal)
          )
    );
  }, [
    paymentTermsWatch,
    promoWatch,
    discountWatch,
    fullPaymentWatch,
    scheduledDownpaymentWatch,
    reservationDownpaymentWatch,
  ]);

  useEffect(() => {
    if (paymentTypeWatch.value) {
      const selectedPayType = rawPaymentTypeData?.find(
        (item) => item.id === Number(paymentTypeWatch.value)
      );

      if (selectedPayType) {
        paymentFieldsReplace(
          selectedPayType.paymentFields.map(
            (item): { [name: string]: string } => ({
              [`${replaceAllSpaceWithHyphen(item.entries)}`]: "",
            })
          )
        );
        paymentFieldRef.current = selectedPayType.paymentFields.map(
          (item) => `${replaceAllSpaceWithHyphen(item.entries)}`
        );
        paymentFieldIdRef.current = selectedPayType.paymentFields.map(
          (item) => item.id
        );
      }
    }
  }, [paymentTypeWatch]);

  return loading ? (
    <Box paddingY={3}>
      <LoaderWithText text="Getting unit information.." />
    </Box>
  ) : (
    <>
      {openUpload && (
        <PaymentUpload
          open={openUpload}
          title={`${unit.type} ${showName(unit.unit, unit.type)} Documents`}
          buyerPaymentId={paymentData?.data.paymentId ?? null}
          onClose={toggleUploadDoc}
        />
      )}
      <FileViewModal
        open={viewDoc.open}
        path={viewDoc.path}
        title={viewDoc.path.substring(
          viewDoc.path.lastIndexOf("/") + 1,
          viewDoc.path.length
        )}
        onClose={() => handelToggleViewDoc(false, "")}
      />
      <Box
        bgcolor={
          paymentData?.data?.id ? theme.palette.grey[100] : "transparent"
        }
        padding={"10px"}
        borderRadius={"5px"}
      >
        <Typography variant="body1" marginBottom={1}>
          <Typography component="span" marginRight={1} variant="textSm">
            Unit:
          </Typography>
          <Typography
            component="span"
            variant="textSm"
            sx={(theme) => ({
              color: theme.palette.primary.main,
              fontWeight: 700,
              marginBottom: 2,
            })}
          >
            {`${unit.type} ${showName(unit.unit, unit.type)}`}
          </Typography>
        </Typography>
        <FormProvider {...form}>
          <Grid container columnSpacing={6} rowSpacing={2}>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2}>
                <div>
                  <Typography variant="textfieldLabel">
                    Contract Date
                  </Typography>
                  <Controller
                    name="contractDate"
                    rules={{
                      required: {
                        value: true,
                        message: thisFieldRequired,
                      },
                    }}
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            {...field}
                            slotProps={{
                              textField: {
                                variant: "outlined",
                                error: fieldState.error?.message ? true : false,
                                helperText: fieldState.error?.message,
                                fullWidth: true,
                              },
                            }}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">
                    Payment Terms
                  </Typography>
                  <Controller
                    name="paymentTerms"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please select a payment term.",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <RadioGroup
                          {...field}
                          value={paymentTermsWatch}
                          onChange={handlePaymentTermsOnChange}
                        >
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3.6}>
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="FULL-PAYMENT"
                                label="Full payment"
                                control={<Radio />}
                              />
                            </Grid>
                            <Grid item xs={4.2}>
                              <Controller
                                name="fullPaymentAmount"
                                rules={{
                                  required: {
                                    value: paymentTermsWatch === "FULL-PAYMENT",
                                    message: thisFieldRequired,
                                  },
                                }}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    disabled={
                                      paymentTermsWatch === "FULL-PAYMENT"
                                        ? false
                                        : true
                                    }
                                    placeholder="Amount"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error?.message}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={4.2}>
                              <Controller
                                name="atNeed"
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    sx={{
                                      ".MuiTypography-root": {
                                        fontSize: ".85rem",
                                      },
                                    }}
                                    label={`At Need (${
                                      unit?.unit?.atNeed
                                        ? unit.unit.atNeed + unit.unit.pcfAtNeed
                                        : ""
                                    })`}
                                    control={
                                      <Checkbox
                                        {...field}
                                        checked={field.value}
                                      />
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            marginTop="1px"
                          >
                            <Grid item xs={3.6}>
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="SCHEDULED"
                                label="Scheduled"
                                control={<Radio />}
                              />
                            </Grid>
                            <Grid item xs={4.2}>
                              <CustomReactSelectJs
                                control={control}
                                options={scheduledMonthsOpt}
                                name="scheduledMonths"
                                placeholder="Months"
                                isClearable={true}
                                isDisabled={
                                  paymentTermsWatch === "SCHEDULED"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            <Grid item xs={4.2}>
                              <Controller
                                name="scheduledDownpayment"
                                control={control}
                                rules={{
                                  required: {
                                    value: paymentTermsWatch === "SCHEDULED",
                                    message: thisFieldRequired,
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    disabled={
                                      paymentTermsWatch === "SCHEDULED"
                                        ? false
                                        : true
                                    }
                                    placeholder="Downpayment"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error?.message}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            marginTop="1px"
                          >
                            <Grid item xs={3.6}>
                              <FormControlLabel
                                sx={{
                                  label: {
                                    fontSize: ".9rem",
                                  },
                                }}
                                value="RESERVATION"
                                label="Reservation"
                                control={<Radio />}
                              />
                            </Grid>
                            <Grid item xs={4.2}>
                              <Controller
                                name="reservationDownpayment"
                                rules={{
                                  required: {
                                    value: paymentTermsWatch === "RESERVATION",
                                    message: thisFieldRequired,
                                  },
                                }}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    disabled={
                                      paymentTermsWatch === "RESERVATION"
                                        ? false
                                        : true
                                    }
                                    placeholder="Downpayment"
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error?.message}
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                        {error && (
                          <FormHelperText error>{error.message}</FormHelperText>
                        )}
                      </>
                    )}
                  />
                </div>
                <div>
                  <Typography variant="textfieldLabel">Payment</Typography>
                  <Stack spacing={2}>
                    <div>
                      <Typography variant="textfieldLabel">
                        Payment Type
                      </Typography>
                      <CustomReactSelectJs
                        control={control}
                        options={paymentTypeOptions}
                        name="paymentType"
                        placeholder="Payment Type"
                      />
                    </div>
                    {!_.isNull(paymentTypeWatch.value) && (
                      <>
                        {paymentFieldsWatch.map((item, key) => {
                          const itemKeyName = Object.keys(item)[0];

                          const itemLabelName =
                            replaceAllHyphenWithSpace(itemKeyName);

                          return (
                            <div key={key}>
                              <Typography variant="textfieldLabel">
                                {itemLabelName}
                              </Typography>
                              <Controller
                                name={`paymentFields.${key}.${itemKeyName}`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: thisFieldRequired,
                                  },
                                }}
                                control={control}
                                render={({ field, fieldState }) => {
                                  if (
                                    itemKeyName.toLowerCase().includes("date")
                                  ) {
                                    return (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DatePicker
                                          {...field}
                                          slotProps={{
                                            textField: {
                                              variant: "outlined",
                                              error: fieldState.error?.message
                                                ? true
                                                : false,
                                              helperText:
                                                fieldState.error?.message,
                                              fullWidth: true,
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>
                                    );
                                  }
                                  return (
                                    <TextField
                                      {...field}
                                      placeholder={itemLabelName}
                                      error={fieldState.error ? true : false}
                                      helperText={fieldState.error?.message}
                                      sx={{
                                        width: "100%",
                                      }}
                                    />
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                    <div>
                      <Typography variant="textfieldLabel">Receipt</Typography>
                      <CustomReactSelectJs
                        control={control}
                        options={receiptOpt}
                        name="receipt"
                        placeholder="Receipt"
                      />
                    </div>
                    <div>
                      <Typography variant="textfieldLabel">
                        OR Number
                      </Typography>
                      <Controller
                        name="orNumber"
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: thisFieldRequired,
                        //   },
                        // }}
                        control={control}
                        render={({ field, fieldState }) => {
                          return (
                            <TextField
                              {...field}
                              placeholder="OR Number"
                              error={fieldState.error ? true : false}
                              helperText={fieldState.error?.message}
                              sx={{
                                width: "100%",
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                  </Stack>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                sx={(theme) => ({
                  borderRadius: 1,
                  border: `2px solid ${theme.palette.primary.main}`,
                  padding: theme.spacing(2),
                  background: theme.palette.grey[100],
                  marginBottom: 4,
                })}
              >
                <Stack spacing={2}>
                  <div>
                    <Typography variant="textfieldLabel">Price</Typography>
                    <Controller
                      name="unitDetails.price"
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Price"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">PCF</Typography>
                    <Controller
                      name="unitDetails.pcf"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: thisFieldRequired,
                      //   },
                      // }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="PCF"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">Payment</Typography>
                    <Controller
                      name="unitDetails.payment"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: thisFieldRequired,
                      //   },
                      // }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Payment"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">
                      Reservation
                    </Typography>
                    <Controller
                      name="unitDetails.reservation"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: thisFieldRequired,
                      //   },
                      // }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Reservation"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">Discount</Typography>
                    {discountFields.map((item, key) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        key={key}
                        paddingTop={key === 0 ? 1 : 2}
                      >
                        <IconButtonMdi
                          mdiIcon={
                            key + 1 === discountWatch.length
                              ? mdiPlusCircleOutline
                              : mdiMinusCircleOutline
                          }
                          iconColor={
                            key + 1 === discountWatch.length
                              ? theme.palette.info.main
                              : theme.palette.error.main
                          }
                          onClick={() =>
                            handleAddRemoveFieldArray(
                              key + 1 === discountWatch.length ? null : key,
                              "discount"
                            )
                          }
                        />
                        <Box width="100%">
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={8}>
                              <CustomReactSelectJs
                                name={`unitDetails.discounts.${key}.value`}
                                control={control}
                                options={discountOptions}
                                placeholder="Discount"
                                // @ts-ignore
                                value={discountWatch[key]?.value}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="textfieldLabel">
                                {showDiscountPromo(
                                  discountWatch[key]?.value?.value ?? ""
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    ))}
                  </div>
                  <div>
                    <Typography variant="textfieldLabel">Promo</Typography>
                    {promoFields.map((item, key) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        key={key}
                        paddingTop={key === 0 ? 1 : 2}
                      >
                        <IconButtonMdi
                          mdiIcon={
                            key + 1 === promoWatch.length
                              ? mdiPlusCircleOutline
                              : mdiMinusCircleOutline
                          }
                          iconColor={
                            key + 1 === promoWatch.length
                              ? theme.palette.info.main
                              : theme.palette.error.main
                          }
                          onClick={() =>
                            handleAddRemoveFieldArray(
                              key + 1 === promoWatch.length ? null : key,
                              "promo"
                            )
                          }
                        />
                        <Box width="100%">
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={8}>
                              <CustomReactSelectJs
                                name={`unitDetails.promo.${key}.value`}
                                control={control}
                                options={promoOptions}
                                placeholder="Promo"
                                // @ts-ignore
                                value={promoWatch[key]?.value}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="textfieldLabel">
                                {showDiscountPromo(
                                  promoWatch[key]?.value?.value ?? ""
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    ))}
                  </div>
                  <Divider />
                  <div>
                    <Typography variant="textfieldLabel">Balance</Typography>
                    <Controller
                      name="unitDetails.balance"
                      rules={{
                        required: {
                          value: true,
                          message: thisFieldRequired,
                        },
                      }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder="Balance"
                          error={fieldState.error ? true : false}
                          helperText={fieldState.error?.message}
                          sx={{
                            width: "100%",
                          }}
                        />
                      )}
                    />
                  </div>
                </Stack>
              </Box>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="textfieldLabel">
                  Payment Documents
                </Typography>
                <Button
                  variant="button-secondary"
                  disabled={buyerId ? false : true}
                  sx={{
                    width: 150,
                  }}
                  startIcon={
                    saveLoading && (
                      <CircularProgress
                        size={20}
                        sx={(theme) => ({
                          color: theme.palette.common.white,
                        })}
                      />
                    )
                  }
                  onClick={toggleUploadDoc}
                >
                  Upload
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {paymentData?.data?.id === undefined && (
            <Button
              variant="button-primary"
              disabled={buyerId ? false : true}
              sx={{
                marginTop: 4,
                width: 150,
              }}
              startIcon={
                saveLoading && (
                  <CircularProgress
                    size={20}
                    sx={(theme) => ({
                      color: theme.palette.common.white,
                    })}
                  />
                )
              }
              onClick={handleSubmit(onSubmit, onError)}
            >
              Pay
            </Button>
          )}
        </FormProvider>
      </Box>
    </>
  );
};

export default PaymentForm;
