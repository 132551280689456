import React from "react";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import Select from "react-select";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Controller,
  useFormState,
  Control,
  Path,
  FieldValues,
} from "react-hook-form";
import { UserForm } from "../../user-management/users/UserInformation";
import { thisFieldRequired } from "../../../utils/constants";

const CustomReactSelectJs = ({
  name,
  control,
  isRequired = false,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  isLoading = false,
  placeholder = "Select",
  options = [],
  customOnchange = null,
  value = null,
  noOptionsMessage = "No options",
  // handleChange = null,
}) => {
  const formState = useFormState();
  const theme = useTheme();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 40.7,
      background: state.isDisabled ? theme.palette.grey[200] : "#fff",
      border: state.isFocused
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${
            formState.errors[name]?.message
              ? theme.palette.error.main
              : theme.palette.grey[400]
          }`,
      width: "100%",
      fontSize: "0.85rem",
      fontWeight: "500",
      borderRadius: theme.spacing(1),
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: "10px 15px",
    }),
    placeholder: (base, state) => ({
      ...base,
      margin: 0,
      color: "#cfd5de",
      fontWeight: "400",
      fontSize: ".8rem",
    }),
    input: (base, state) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: theme.palette.primary.main,
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      background: "transparent",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: state.isDisabled
        ? theme.palette.grey[400]
        : theme.palette.common.black,
      margin: 0,
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 2,
      // display: selectedOthers ? "none" : "block",
    }),
    menuList: (base, state) => ({
      ...base,
      // maxHeight: menuMaxHeight && menuMaxHeight,
      "&::-webkit-scrollbar": {
        width: "10px",
        height: "100%",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f0eef7",
        borderRadius: "50px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#9b745b",
        borderRadius: "50px",
        border: "2px solid #f0eef7",
      },
    }),
    option: (base, state) => ({
      ...base,
      fontSize: "0.85rem",
      fontWeight: "500",
      color: "#000",
      "&:hover": {
        background: "#f0eef7",
      },
      "&:active": {
        background: "#f0eef7",
      },
    }),
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: isRequired,
            message: thisFieldRequired,
          },
        }}
        render={({ field }) => (
          <Select
            {...field}
            isClearable={isClearable}
            value={value ?? field.value}
            options={options}
            styles={customStyles}
            placeholder={placeholder}
            isDisabled={isDisabled}
            onChange={customOnchange ?? field.onChange}
            noOptionsMessage={() => noOptionsMessage}
          />
        )}
      />
      {formState.errors[name]?.message && (
        <div
          style={{
            marginTop: 3,
          }}
        >
          <FormHelperText
            error
            sx={{
              marginLeft: "14px",
            }}
          >
            {thisFieldRequired}
          </FormHelperText>
        </div>
      )}
    </div>
  );
};

export default CustomReactSelectJs;
